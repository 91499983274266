.k-pdf-export {
    background: #f1f5f7 !important;
}

/*to be hidden*/
.k-pdf-export>div>div>div:nth-child(2)>div>div>div>div:nth-child(5) {
    display: none !important;
}

.k-pdf-export>div>div>div:nth-child(2)>div>div>div>button {
    display: none !important;
}

.k-pdf-export .col-xl-12>.row:nth-child(2) {
    display: none !important;
}

.k-pdf-export .col-md-8.col-lg-8.col-xl-4,
.k-pdf-export .col-md-4.col-lg-4.col-xl-2 {
    display: none !important;
}

.k-pdf-export .overflow-hidden.media-body>img {
    height: 100% !important;
}

/*image block arrangement*/
.k-pdf-export .col-xl-12>div {
    display: block;
    width: 230vw;
}

/* centering the image block */
.k-pdf-export .top>div>.col-xl-12>div {
    left: 50%;
    position: relative;
    transform: translateX(-8%);
    display: block;
    width: 230vw;
}

.k-pdf-export .top>div>.col-xl-12> {
    left: 50%;
    position: relative;
    transform: translateX(-8%);
}

/* resizing all*/
.k-pdf-export .col-xl-12>div>.page-break.o-break.col-sm-6.col-md-3.col-lg-3.col-xl-2 {
    margin-top: 80px !important;
}

.k-pdf-export .col-xl-12>div>.page-break.d-break.col-sm-6.col-md-3.col-lg-3.col-xl-2 {
    margin-top: 170px !important;
}

.k-pdf-export .top>div>.col-xl-12>div>div {
    width: 36pc;
}

.k-pdf-export .col-xl-12>div>.e-break {
    margin-top: 135px !important;
}

.k-pdf-export .col-xl-12>div>.page-break.s-break.col-sm-6.col-md-3.col-lg-3.col-xl-2 {
    margin-top: 160px !important;
}


/*Tympanic*/
.k-pdf-export .col-xl-12>.row>.col-sm-12.col-md-6.col-lg-6.col-xl-4>div>div {
    display: block;
}

.k-pdf-export .col-xl-12>.row:nth-child(3)>div>div {
    width: 35.3pc;
}

/*eyes*/
.k-pdf-export .col-xl-12>.row:nth-child(4)>div>div {
    width: 36pc;
}

/*vitals*/
.k-pdf-export .col-xl-8>div>div:nth-child(6)>div>div>div>div>h4,
.k-pdf-export .col-xl-8>div>div:nth-child(7)>div>div>div>div>h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.k-pdf-export .col-xl-8 .col-md-3 .card>div:first-child {
    padding-left: 0.75em;
    font-size: 7px;
    padding-right: 0.9em;
}

.k-pdf-export .col-xl-8>div>div:nth-child(6)>div>div,
.k-pdf-export .col-xl-8>div>div:nth-child(7)>div>div {
    padding-right: 10px;
}

/*oropharynx*/
.k-pdf-export .top>.row>.col-xl-12>div:nth-child(1)>div:nth-child(1) {
    margin-top: 20px !important;
}

/*complaint*/
.k-pdf-export .top>.row>div:nth-child(3)>div>div {
    padding-left: 0pc !important;
}

.k-pdf-export .col-md-4>div {
    width: 36pc;
}
